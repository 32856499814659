import { Button } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';

const HeroCalendarComponent = () => {

  useEffect(() => {
    const script = document.createElement('script');

    script.innerHTML = `
      (function () {
        var target = document.getElementById('hero-calendar');
        if (target) {
          calendar.schedulingButton.load({
            url: 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ1c8i-K6vWOCKsTQxkWyWvh_kwR31YsCc_FRteawBiyuYap7STnWEerxzYJUUwkTvZqW1u9FvBh?gv=true',
            color: '#5266EB',
            label: 'Get Started',
            target: target,
          });
        }
      })();
    `;

    document.body.appendChild(script);
    document.querySelector('.MuiStack-root > .MuiBox-root > .qxCTlb').addEventListener("click", handleGetStartedClick)
    document.querySelector('.MuiStack-root > .MuiBox-root > .qxCTlb').style.padding = '14px 16px'

    return () => {
      document.body.removeChild(script);
      document.querySelector('.MuiStack-root > .MuiBox-root > .qxCTlb').removeEventListener("click", handleGetStartedClick)
    };
  }, []);

  const handleGetStartedClick = () => {
    mixpanel.track("Get started clicked")
  }

  return (
    <span id="hero-calendar" onClick={handleGetStartedClick}></span>
  );
};

export default HeroCalendarComponent;
