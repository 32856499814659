import { Box, Button } from '@mui/material';
import './App.css';
import LandingPage from './pages/LandingPage/LandingPage';

function App() {
  return (
    <Box sx={{minHeight: '100vh'}}>
      <LandingPage />
    </Box>
  );
}

export default App;
