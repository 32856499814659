import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
const leftFooter = (
  <Typography
    variant="h4"
    component="div"
    sx={{ flexGrow: 1, color: "white", fontWeight: 600 }}
    fontSize={{ xs: 26, md: 34 }}
  >
    meltt.ai
  </Typography>
);

const rightFooter = (
  <Stack direction="column" spacing={1}>
    <Stack direction="row">
      <LocalPhoneIcon style={{ color: "white", marginRight: 5 }} />
      <a href="tel:07060904435" style={{ textDecoration: "none" }}>
        <Typography color="white">+91-7060904435</Typography>
      </a>
    </Stack>

    <Stack direction="row" display={"flex"} alignItems={"center"}>
      <EmailIcon style={{ color: "white", marginRight: 5 }} />
      <a href="mailto:contact@meltt.ai" style={{ textDecoration: "none" }}>
        <Typography color="white" style={{ textDecoration: "none" }}>
          contact@meltt.ai
        </Typography>
      </a>
    </Stack>
    <Stack direction="row">
      <LocationOnIcon style={{ color: "white", marginRight: 5 }} />
      <Typography color="white">HSR Layout, Bangalore, KA</Typography>
    </Stack>
  </Stack>
);

export default function Footer() {
  return (
    <Stack
      style={{
        backgroundColor: "black",
        minHeight: 150,
      }}
      padding={{ xs: "60px" }}
      paddingLeft={{ xs: "35px", md: "60px" }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        display={"flex"}
        justifyItems={"center"}
        spacing={4}
      >
        {leftFooter}
        {rightFooter}
      </Stack>
      <Box display={"flex"} justifyContent={"center"} mt={5}>
        <Typography color={"grey"} fontSize={12}>
          © 2024 – meltt.ai
        </Typography>
      </Box>
    </Stack>
  );
}
