import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { theme } from './utils/theme';
import mixpanel from 'mixpanel-browser';

const root = ReactDOM.createRoot(document.getElementById('root'));
let env_check = process.env.NODE_ENV === 'production';

if (env_check){
    mixpanel.init('ead02570148b5e829a7c01c195564f35', {
        track_pageview: true,
        persistence: "localStorage",
      });
}


root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </StyledEngineProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();