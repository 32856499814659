import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CalendarComponent from "./Calender-header";

export default function Header() {

  return (
    <Box sx={{ flexGrow: 1, height: "10vh" }}>
      <AppBar
        position="fixed"
        sx={{ p: 2, backgroundColor: "white" }}
        elevation={1}
      >
        <Toolbar>
          <Typography
            variant="h4"
            component="div"
            sx={{ flexGrow: 1, color: "black", fontWeight: 600 }}
            fontSize={{ xs: 26, md: 34 }}
          >
            meltt.ai
          </Typography>
          <CalendarComponent />
          {/* <Button
            target="_blank"
            href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1c8i-K6vWOCKsTQxkWyWvh_kwR31YsCc_FRteawBiyuYap7STnWEerxzYJUUwkTvZqW1u9FvBh?gv=true"
            style={{ color: "black", textTransform: "initial", fontSize: 16 }}
          >
            Book a demo
          </Button> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
