import { Box, Stack, styled, Typography } from "@mui/material";
import pricingStrategy from "../assets/pricing-strategy.png";
import optimalMarkdown from "../assets/optimal-markdown.png";
import businessGrowth from "../assets/business-growth.png";

const featureTitle = (
  <Box display="flex" justifyContent="center">
    <Typography sx={{ fontWeight: 500 }} fontSize={{ xs: 26, md: 40 }}>
      Features
    </Typography>
  </Box>
);

const FeatureTitle = ({ title }) => {
  return (
    <Typography
      alignSelf={{ xs: "center", md: "center" }}
      textAlign={'center'}
      sx={{ fontSize: { xs: "20px", md: "24px" } }}
      gutterBottom
    >
      {title}
    </Typography>
  );
};

const FeatureDesc = ({ desc }) => {
  return (
    <Typography
      alignSelf={"center"}
      textAlign={'center'}
      sx={{ fontSize: { xs: "16px", md: "17px" } }}
      gutterBottom
    >
      {desc}
    </Typography>
  );
};

const FeatureStack = ({ children, ...props }) => {
  return (
    <Stack
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      maxWidth={400}
      {...props}
    >
      {children}
    </Stack>
  );
};


const featureList2 = (
  <Box display="flex" justifyContent="center" padding={{ xs: 4, md: 10 }}>
    <Stack direction={{ xs: "column", sm: "row" }} spacing={{ xs: 8 }}>
      <FeatureStack>
        <img
          src={pricingStrategy}
          style={{
            maxWidth: "100px",
          }}
        />
        <FeatureTitle title={"Intelligent Pricing Strategy"} />
        <FeatureDesc
          desc={
            "Update pricing proactively, using real-time sales, inventory & competitor data*"
          }
        />
      </FeatureStack>
      <FeatureStack>
        <img
          src={optimalMarkdown}
          style={{
            maxWidth: "100px",
          }}
        />
        <FeatureTitle title={"Pricing Recommendations"} />
        <FeatureDesc
          desc={
            "Get optimal prices for each SKU using our models tested at scale"
          }
        />
      </FeatureStack>
      <FeatureStack>
        <img
          src={businessGrowth}
          style={{
            maxWidth: "100px",
          }}
        />
        <FeatureTitle title={"Scenario Analysis"} />
        <FeatureDesc
          desc={
            "Simulate various pricing scenarios, visualise impact on your business metrics"
          }
        />
      </FeatureStack>
    </Stack>
  </Box>
);

export default function Features() {
  return (
    <Stack minHeight={"80vh"} justifyContent={"center"}>
      {featureTitle}
      {featureList2}
    </Stack>
  );
}
