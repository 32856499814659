import React from "react";
import { Grid, Typography, Box, Stack, SvgIcon, styled } from "@mui/material";
import IndustryStandardCard from "../assets/Industry-standard-card.svg";
import PriceElasticityModels from "../assets/Price-elasticity-models.svg";
import TailoredSpecificCard from "../assets/tailored-specific-card.svg";
import PreciselyTunedCard from "../assets/precisely-tuned-feature-selection-card.svg";

const reasonsTitle = (
  <Box display="flex" justifyContent="center">
    <Typography sx={{ fontWeight: 500 }} fontSize={{ xs: 26, md: 40 }}>
      Why meltt.ai?
    </Typography>
  </Box>
);

const ResponsiveImage = styled("img")(({ theme }) => ({
  maxHeight: "200px",
  [theme.breakpoints.down("md")]: {
    maxHeight: "150px",
    width: "auto",
    marginBottom: "25px",
  },
}));

const CommonGridItem = ({ children, bt, br, bl, bb, ...props }) => {
  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        padding: "0 !important",
        borderTop: bt ? { md: "1px solid #ddd", xs: "none" } : "none",
        borderRight: br ? { md: "1px solid #ddd", xs: "none" } : "none",
        borderBottom: bb ? { md: "1px solid #ddd", xs: "none" } : "none",
        borderLeft: bl ? { md: "1px solid #ddd", xs: "none" } : "none",
      }}
      {...props} // Pass down any additional props
    >
      <Box p={{ xs: 4, md: 4 }} pb={{ xs: 0, md: 4 }} textAlign="center">
        {children}
      </Box>
    </Grid>
  );
};

const TextBlock = ({ title, description }) => {
  return (
    <Box textAlign={{ xs: "left", md: "center" }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography>{description}</Typography>
    </Box>
  );
};

const ReasonTitle = ({ title }) => {
  return (
    <Typography sx={{ fontSize: { xs: "20px", md: "24px" } }} gutterBottom>
      {title}
    </Typography>
  );
};

const ReasonDesc = ({ desc }) => {
  return (
    <Typography sx={{ fontSize: { xs: "16px", md: "17px" } }} gutterBottom>
      {desc}
    </Typography>
  );
};

const reasonsList = (
  <Box
    display={"flex"}
    justifyContent={"center"}
    alignItems={"center"}
    p={{ xs: 0, md: 4 }}
    mt={1}
    mb={6}
  >
    <Grid container spacing={{ xs: 0 }}>
      {/* First Row */}
      <CommonGridItem bb br>
        <TextBlock
          title="Price elasticity models proven at Scale"
          description="Take control of your brand’s P&L. Make proactive pricing decisions with confidence"
        />
      </CommonGridItem>

      <CommonGridItem bb>
        <TextBlock
          title="Model accuracy of up to 85%"
          description="Our models account for seasonality, marketing activities, stock availability, and competitor data*"
        />
      </CommonGridItem>

      {/* Second Row */}
      <CommonGridItem br>
        <TextBlock
          title="Tailored models specific to your business"
          description="Models trained from scratch on your data, customized for both online and offline channels"
        />
      </CommonGridItem>
      <CommonGridItem>
        <TextBlock
          title="Precisely tuned feature selection"
          description="Significant effort is invested in feature engineering, carefully extracting the right features from your data to enhance model training"
        />
      </CommonGridItem>
    </Grid>
  </Box>
);

const reasonsList2 = (
  <Grid padding={{ xs: 4, md: 15, lg: 30 }} pt={{ md: 10, xs: 5, lg: 10 }}>
    <Box
      display={"flex"}
      flexDirection={{ xs: "column", md: "row" }}
      mb={{ xs: 6, md: 8 }}
    >
      <Box
        width={{ md: "40%", xs: "100%" }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={{ md: "left", xs: "center" }}
      >
        <ResponsiveImage src={PriceElasticityModels}></ResponsiveImage>
      </Box>
      <Box
        width={{ md: "60%", xs: "100%" }}
        textAlign={{ xs: "center", md: "right" }}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignContent={"flex-end"}
      >
        <ReasonTitle title={"Price elasticity models proven at scale"} />
        <ReasonDesc
          desc={" Make proactive pricing decisions with confidence"}
        />
      </Box>
    </Box>

    <Box
      display={"flex"}
      flexDirection={{ xs: "column-reverse", md: "row" }}
      mb={{ xs: 6, md: 8 }}
    >
      <Box
        width={{ md: "60%", xs: "100%" }}
        textAlign={{ xs: "center", md: "left" }}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
      >
        <ReasonTitle title={"Industry standard model accuracy"} />
        <ReasonDesc
          desc={
            "Accounts seasonality, marketing activities, stock availability, and competitor data*"
          }
        />
      </Box>

      <Box
        width={{ md: "40%", xs: "100%" }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={{ md: "right", xs: "center" }}
      >
        <ResponsiveImage src={IndustryStandardCard}></ResponsiveImage>
      </Box>
    </Box>

    {/* Card 3 */}
    <Box
      display={"flex"}
      flexDirection={{ xs: "column", md: "row" }}
      mb={{ xs: 6, md: 8 }}
    >
      <Box
        width={{ md: "40%", xs: "100%" }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={{ md: "left", xs: "center" }}
      >
        <ResponsiveImage src={TailoredSpecificCard}></ResponsiveImage>
      </Box>
      <Box
        width={{ md: "60%", xs: "100%" }}
        textAlign={{ xs: "center", md: "right" }}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
      >
        <ReasonTitle title={"Tailored specific to your business"} />
        <ReasonDesc
          desc={"Models trained on your data, customised for all channels"}
        />
      </Box>
    </Box>

    {/* Card 4 */}

    <Box
      display={"flex"}
      flexDirection={{ xs: "column-reverse", md: "row" }}
      mb={{ xs: 6, md: 8 }}
    >
      <Box
        width={{ md: "60%", xs: "100%" }}
        textAlign={{ xs: "center", md: "left" }}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
      >
        <ReasonTitle title={"Precisely tuned feature selection"} />
        <ReasonDesc
          desc={
            "Deep feature engineering, features are carefully extracted to enhance model training"
          }
        />
      </Box>

      <Box
        width={{ md: "40%", xs: "100%" }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={{ md: "right", xs: "center" }}
      >
        <ResponsiveImage src={PreciselyTunedCard}></ResponsiveImage>
      </Box>
    </Box>
  </Grid>
);

export default function ReasonsComponent() {
  return (
    <Stack mt={4} minHeight={"90vh"} justifyContent={"center"}>
      {reasonsTitle}
      {reasonsList2}
    </Stack>
  );
}
