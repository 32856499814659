import { Button } from '@mui/material';
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';

const CalendarComponent = () => {

  useEffect(() => {
    const script = document.createElement('script');

    script.innerHTML = `
      (function () {
        var target = document.getElementById('calendar');
        if (target) {
          calendar.schedulingButton.load({
            url: 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ1c8i-K6vWOCKsTQxkWyWvh_kwR31YsCc_FRteawBiyuYap7STnWEerxzYJUUwkTvZqW1u9FvBh?gv=true',
            color: '#5266EB',
            label: 'Book a meeting',
            target: target,
          });
        }
      })();
    `;

    document.body.appendChild(script);
    document.querySelector('header .qxCTlb').addEventListener("click", handleHeaderClick)
    document.querySelector('header .qxCTlb').style.padding = '10px 16px'
    

    return () => {
      document.body.removeChild(script);
      document.querySelector('header .qxCTlb').removeEventListener("click", handleHeaderClick)
    };
  }, []);

  const handleHeaderClick = () => {
    mixpanel.track("Book a meeting clicked")
  }

  return (
    <Button id="calendar"></Button>
  );
};

export default CalendarComponent;
