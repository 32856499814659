import { ArrowRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ImageList,
  Stack,
  Typography,
} from "@mui/material";
import HeroCalendarComponent from "./Calender-hero";
import salesChart from "../assets/chart.png";

const leftSection = (
  <Grid
    sx={{
      flexGrow: 1,
    }}
    item
    container
    sm={6}
    xs={12}
    justifyContent="center"
    alignItems="center"
  >
    <Stack marginTop={{ xs: 3, md: 0 }} spacing={4} sx={{ zIndex: 10 }}>
      <Typography
        sx={{ lineHeight: 1.2, fontWeight: 700 }}
        fontSize={{ xs: 30, md: 65 }}
      >
        Say goodbye to gut based pricing <span class="wave">👋</span>
      </Typography>
      {/* <Stack direction="row" alignItems={"center"} spacing={6}> */}
      <Typography fontSize={{ xs: 17, md: 21 }}>
      Discover prices that drive sales, using cutting-edge AI models tested at scale
      </Typography>
      <Box display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
        <HeroCalendarComponent />
      </Box>
      {/* <Button target="_blank" href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1c8i-K6vWOCKsTQxkWyWvh_kwR31YsCc_FRteawBiyuYap7STnWEerxzYJUUwkTvZqW1u9FvBh?gv=true" variant="contained" sx={{backgroundColor: 'black', color:'white', textTransform: 'capitalize', borderRadius: 10, fontSize: 18, width: 180, p: 1}}>
            Get Started
            </Button> */}
      {/* </Stack> */}
      {/* <Divider /> */}
    </Stack>
  </Grid>
);

const rightSection = (
  <Grid
    sx={{
      flexGrow: 1
    }}
    item
    container
    sm={5}
    xs={12}
    justifyContent="center"
    alignItems="center"
  >
      <img
        src={salesChart}
        style={{
          // position: "absolute",
          // left: -60,
          // opacity: 0.6,
          zIndex: 1,
          width: "100%",
        }}
      />
    {/* <Stack spacing={6} sx={{ zIndex: 10 }}>
      <Stack spacing={3}>
        <Typography variant="h1" sx={{ lineHeight: 1.2, fontWeight: 700 }}>
          Trade Stocsk, <br /> earn bonus
        </Typography>
        <Stack direction="row" alignItems={"center"} spacing={6}>
          <Typography sx={{fontSize: 20}}>
            loadasdas <br /> adsadbias dashdoas a d ashdha das <br />
          </Typography>
          <Box sx={{
            p: 1,
            bgcolor: (theme) => theme.palette.common.black
          }}>
            <IconButton>
                <ArrowRight />
            </IconButton>
          </Box>
        </Stack>
      </Stack>
      <Divider />
    </Stack> */}
  </Grid>
);

export default function HeroSection() {
  return (
    <Grid
      container
      spacing={{
        xs: 10,
        md: 2,
      }}
      sx={{ flexGrow: 1, minHeight: "91vh" }}
      padding={{ xs: 3, md: 10 }}
      justifyContent={'space-between'}
      id="hero"
    >
      {leftSection}
      {rightSection}
    </Grid>
  );
}
