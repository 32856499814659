import { Box, Stack, Typography } from "@mui/material";
import melttGraph from "../assets/meltt.ai-io-new.svg";
import melttVerticalGraph from "../assets/meltt.ai-io-vertical-new.svg";

const featureTitle = (
  <Box
    display="flex"
    justifyContent="center"
    padding={{ xs: 4, md: 10 }}
    paddingTop={{ md: 0 }}
  >
    <Typography
      textAlign="center"
      sx={{ fontWeight: 500 }}
      fontSize={{ xs: 26, md: 40 }}
    >
      Unlock AI-driven efficiency with meltt.ai
    </Typography>
  </Box>
);

const featureList2 = (
  <Box
    display="flex"
    justifyContent="center"
    padding={{ xs: 2, md: 10 }}
    paddingBottom={{ xs: 6 }}
    paddingTop={{ xs: 0, md: 0 }}
  >
      <Box
    component="img"
    sx={{
      content: {
        xs: `url(${melttVerticalGraph})`, //img src from xs up to md
        md: `url(${melttGraph})`, //img src from md and up
      },
      width: '100%'
    }}
    alt="Logo"
  />
  </Box>
);

export default function GraphImage() {
  return (
    <Stack>
      {featureTitle}
      {featureList2}
    </Stack>
  );
}
