import React from 'react'
import Header from '../../component/Header'
import { Box } from '@mui/material'
import HeroSection from '../../component/HeroSection'
import Features from '../../component/Features'
import Footer from '../../component/Footer'
import GraphImage from '../../component/GraphImage'
import ReasonsComponent from '../../component/Reasons'

function LandingPage() {
  return (
    <Box>
        <Header />
        <HeroSection />
        <GraphImage />
        <Features />
        <ReasonsComponent />
        <Footer />
    </Box>
  )
}

export default LandingPage